
import Vue from 'vue'
import { ILink, ILogotypeData } from '../store/state'

interface LinksComputed {
	links: ILink[],
	listen: string
}

export default Vue.extend<{}, {}, LinksComputed, {}>({
	name: 'Links',
	props: {
		isMenuLinks: {}
	},
	computed: {
		links() {
			return this.$store.getters.links;
		},
		listen() {
			return this.$store.getters.listen;
		}
	}
})
