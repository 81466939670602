
import Vue from 'vue'
import Logotype from './components/Logotype.vue'
import Links from './components/Links.vue'
import LinkButtons from './components/LinkButtons.vue'
import SearchButton from './components/SearchButton.vue'
import Search from './components/Search.vue'
import MenuButton from './components/MenuButton.vue'
import Menu from './components/Menu.vue'
import { resize } from './store/actions'
import { toggleSearch } from './store/actions'
import { ITranslations } from './store/state'

import { FocusTrap } from 'focus-trap-vue'

Vue.component('FocusTrap', FocusTrap);
// Interfaces for component typechecking!
interface AppData {
	isTablet: boolean,
}

interface AppMethods {
	closeSearch(): void
}

interface AppComputed {
	searchOpen: boolean
	isMobile: boolean
	menuOpen: boolean
	hasSuggestions: boolean
	heading: string
	text: string
	translations: ITranslations
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data() {
		return {
			isTablet: window.innerWidth <= 1025
		}
	},
	methods: {
		closeSearch() {       
			this.$store.dispatch(toggleSearch(false))
		},
	},
	computed: {
		searchOpen(){
			return this.$store.getters.searchOpen;
		},
		isMobile() {
			return this.$store.getters.isMobile;
		},
		menuOpen() {
			return this.$store.getters.menuOpen;
		},
		hasSuggestions() {
			return this.$store.getters.suggestions.length > 0;
		},
		heading() {
      return this.$store.getters.heading;
    },
		text() {
      return this.$store.getters.text;
    },
		translations() {
			return this.$store.getters.translations;
		}
	},
	created() {
		this.$store.dispatch(resize(window.innerWidth))

		addEventListener('resize', () => {
			this.$store.dispatch(resize(window.innerWidth))
		})
	},
	
	components: {
		Logotype,
		Links,
		LinkButtons,
		SearchButton,
		Search,
		MenuButton,
		Menu
	}
})
