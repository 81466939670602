
import Vue from 'vue'
import { ILink, ILogotypeData, ITranslations } from '../store/state'
import { toggleSearch } from '../store/actions'
interface SearchButtonMethods {
	openSearch(): void,
}
interface SearchButtonComputed {
    searchOpen: boolean
    isMobile: boolean
    translations: ITranslations
}

export default Vue.extend<{}, SearchButtonMethods, SearchButtonComputed, {}>({
	name: 'SearchButton',
	methods: {
        openSearch() {
            this.$store.dispatch(toggleSearch(true))
        }
    },
    computed: {
        searchOpen() {
            return this.$store.getters.searchOpen;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        translations() {
            return this.$store.getters.translations;
        }
    },
    watch: {
        searchOpen(newValue, oldValue) {
            const openSearchButton = this.$refs.searchButton as HTMLButtonElement
            if(newValue == false && oldValue == true) {
                openSearchButton.focus()
            }
        }
    }
})
