
import Vue from 'vue'
import { ILink, ILogotypeData, ITranslations } from '../store/state'
import { toggleMenu } from '../store/actions'
interface MenuButtonMethods {
	openMenu(): void,
}
interface MenuButtonComputed {
    isMobile: boolean
    translations: ITranslations
    searchOpen: boolean
}


export default Vue.extend<{}, MenuButtonMethods, MenuButtonComputed, {}>({
	name: 'MenuButton',
	methods: {
        openMenu() {
            this.$store.dispatch(toggleMenu(true))
            const html = document.documentElement;
            html.setAttribute('menu-open', '');
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        translations() {
            return this.$store.getters.translations;
        },
        searchOpen() {
            return this.$store.getters.searchOpen;
        }
    }
    
})
