
import Vue from 'vue'
import { deactivateMainItem, getMenu, toggleMenu } from '../store/actions'
import { IMenuItem, IMenuMainItem, ITranslations } from '../store/state';
import MenuItem from '../components/MenuItem.vue'
import MenuMainItem from '../components/MenuMainItem.vue'
import Links from '../components/Links.vue'


interface MenuComputed {
	menuItems: IMenuMainItem[]
	activeMainItem: IMenuMainItem
	menuOpen: boolean
	translations: ITranslations
}
interface MenuMethods {
	closeMenu(): void
	deactivateMainItem(): void
}

export default Vue.extend<{}, MenuMethods, MenuComputed, {}>({
	name: 'Menu',
	computed: {
		menuItems() {
            return this.$store.getters.menuItems;
        },
		activeMainItem() {
			return this.$store.getters.activeMainItem;
		},
		menuOpen() {
			return this.$store.getters.menuOpen;
		},
		translations() {
			return this.$store.getters.translations;
		}
	},
	methods: {
		closeMenu() {
			this.$store.dispatch(toggleMenu(false));
			const html = document.documentElement;
            html.removeAttribute('menu-open');
		},
		deactivateMainItem() {
			this.$store.dispatch(deactivateMainItem(undefined))
		}
	},
	mounted() {
		this.$store.dispatch(getMenu(undefined))
	
		window.addEventListener('click', (e) => {
			const appRef = this.$refs.menu as HTMLElement;
			const target = e.target as HTMLElement;
			
			if(appRef) {
				if((appRef).contains(target)) {
					
				} else {
					
					if(this.menuOpen && !target.hasAttribute('data-ignore-close')) {
						this.closeMenu()
					}
					
				}
			}
			
		})
	

	},
	components: {
		MenuMainItem,
		Links
	}
})
