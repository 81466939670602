
import Vue from 'vue'
import { ILinkButton } from '../store/state'

interface LinkButtonsComputed {
	linkButtons: ILinkButton[],
}

export default Vue.extend<{}, {}, LinkButtonsComputed, {}>({
	name: 'LinkButtons',
	props: {
	},
	computed: {
		linkButtons() {
			return this.$store.getters.linkButtons;
		},
	}
})
