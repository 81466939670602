
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { toggleExpanded } from '../store/actions'
interface MenuItemMethods {
    toggleClick(item: IMenuItem): void
}

interface MenuItemComputed {
	
}

interface MenuItemProps {
    item: IMenuItem
	
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>({
    name: 'MenuItem',
    props: {
		item: {},
	},
    methods: {
        toggleClick(item) {
            this.$store.dispatch(toggleExpanded(item))
        }
    }, 
    computed: {
        levelClass() {
            return 'level-' + this.$props.item.level;
        }
    },
	components: {
		
	}
})
