import { MutationTree, Mutation } from 'vuex';
import { IMenuItem, IState, ISuggestion } from './state';
import { ActionPayload, IAddMenuItemsPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const toggleSearchMutation: MutationHandler<boolean> = (state, { payload }) => {
	Object.assign(state, { 
		searchOpen: payload
	});
}
const toggleMenuMutation: MutationHandler<boolean> = (state, { payload }) => {
	Object.assign(state.menu, { 
		isOpen: payload
	});
}
const resizeMutation: MutationHandler<number> = (state, { payload }) => {
	Object.assign(state, { 
		isMobile: payload < 1025
	});
}
const setMenuMutation: MutationHandler<IMenuItem[]> = (state, { payload }) => {
	Object.assign(state.menu, { 
		mainItems: payload
	});
}
const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}
const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}
const setActiveMutation: MutationHandler<string> = (state, { payload }) => {
	state.menu.mainItems.forEach(mi => {
		if(mi.id === payload) {
			Object.assign(mi, {isExpanded: true})
		} else {
			Object.assign(mi, {isExpanded: false})
		}
	})
}
const setSuggestionsMutation: MutationHandler<ISuggestion[]> = (state, { payload }) => {
	const key = "query";
	const uniqued = [...new Map(payload.map(item => [item[key], item])).values()];
	Object.assign(state, {suggestions: uniqued})
}
const deactivateMainItemMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	state.menu.mainItems.forEach(mi => {
		Object.assign(mi, {isExpanded: false})
	})
}
const removeSuggestionsMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(state, {suggestions: []})
}
const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	toggleSearch: toggleSearchMutation,
	resize: resizeMutation,
	toggleMenu: toggleMenuMutation,
	setMenu: setMenuMutation,
	addMenuItems: addMenuItemsMutation,
	toggleExpanded: toggleExpandedMutation,
	setActive: setActiveMutation,
	setSuggestions: setSuggestionsMutation,
	deactivateMainItem: deactivateMainItemMutation,
	removeSuggestions: removeSuggestionsMutation,
}

export default mutations
