import { Getter } from "vuex";
import { ILogotypeData, IState } from './state';

const logotypeData: Getter<IState, IState> = (state) => state.logotypeData;
const links: Getter<IState, IState> = (state) => state.links;
const linkButtons: Getter<IState, IState> = (state) => state.linkButtons;
const heading: Getter<IState, IState> = (state) => state.heading;
const text: Getter<IState, IState> = (state) => state.text;
const searchOpen: Getter<IState, IState> = (state) => state.searchOpen;
const menuItems: Getter<IState, IState> = (state) => state.menu.mainItems;
const menuOpen: Getter<IState, IState> = (state) => state.menu.isOpen;
const isMobile: Getter<IState, IState> = (state) => state.isMobile;
const suggestions: Getter<IState, IState> = (state) => state.suggestions;
const searchPageURI: Getter<IState, IState> = (state) => state.endpoints.searchPageURI;
const activeMainItem: Getter<IState, IState> = (state) => {
	const activeItem = state.menu.mainItems.find(mi => mi.isExpanded);
	return activeItem;
}
const logotype: Getter<IState, IState> = (state) => {
	if(state.isEnglish) {
		if(state.searchOpen) {
			const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'eng-neg.svg');
			if(imageData) return imageData.imageURI;
		} else {
			const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'eng-pos.svg');
			if(imageData) return imageData.imageURI;
		}
	} else {
		if(state.searchOpen) {
			const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'sv-neg.svg');
			if(imageData) return imageData.imageURI;
		} else {
			const imageData = state.logotypeData.find((i: ILogotypeData) => i.name === 'sv-pos.svg');
			if(imageData) return imageData.imageURI;
		}
	}

}
const translations: Getter<IState, IState> = (state) => state.translations;
const isStartPage: Getter<IState, IState> = (state) => state.isStartPage;
const startPageURI: Getter<IState, IState> = (state) => state.startPageURI;
const listen: Getter<IState, IState> = (state) => state.listen

export default {
	logotypeData,
	links,
	linkButtons,
	heading,
	text,
	searchOpen,
	isMobile,
	logotype,
	menuOpen,
	menuItems,
	activeMainItem,
	suggestions,
	searchPageURI,
	translations,
	isStartPage,
	startPageURI,
	listen
}
