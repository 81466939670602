
import Vue from 'vue'
import { getSuggestions, removeSuggestions, toggleSearch } from '../store/actions'
import qs from 'query-string'
import { ISuggestion, ITranslations } from '../store/state'

interface SearchData {
	query: string,
    isOpen: boolean
}
interface SearchMethods {
	closeSearch(): void
    getSuggestions(): void
    searchSuggestion(e: Event, suggestion: ISuggestion): void
    toSuggestions(e: Event): void
    nextSuggestion(e: Event, index: number): void
    previousSuggestion(e: Event, index: number): void
    closeSuggestions(focusInput: boolean): void
    clearQuery(event: Event): void
    closeSuggestionsHandler(e: Event): void
    closeIfOutside(e: Event): void
}
interface SearchComputed {
    isMobile: boolean
    suggestions: string[]
    searchPageURI: string
    translations: ITranslations
}

export default Vue.extend<SearchData, SearchMethods, SearchComputed, {}>({
	name: 'Search',
    data(){
        return {
            query: '',
            isOpen: false
        }
    },
	methods: {
		closeSearch() {
            
			this.$store.dispatch(toggleSearch(false))
		},
        getSuggestions() {
            if(this.query.length > 2) {
                this.$store.dispatch(getSuggestions(this.query))
            } else if(this.suggestions.length > 0) {
                this.$store.dispatch(removeSuggestions(undefined))
            }
        },
        searchSuggestion(e, suggestion) {
            e.preventDefault();
            (this.$refs.input as HTMLInputElement).value = suggestion.query;
            (this.$refs.form as HTMLFormElement).submit()
        },
        toSuggestions(e) {
            e.preventDefault();
            if(this.suggestions.length > 0) {
                const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
                suggestions[0].focus();
            }
        },
        nextSuggestion(e, index) {
            e.preventDefault();
            if((index + 1) < this.suggestions.length) {
                const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
                suggestions[index + 1].focus();
            }
            
        },
        previousSuggestion(e, index) {
            e.preventDefault();
            if(index > 0) {
                const suggestions = this.$refs.suggestion as Array<HTMLAnchorElement>;
                suggestions[index - 1].focus();
            } else {
                const input = this.$refs.input as HTMLInputElement;
                input.focus();
            }
            
        },
        closeSuggestions(focusInput) {
            this.$store.dispatch(removeSuggestions(undefined))
            if(focusInput) {
                const input = this.$refs.input as HTMLInputElement;
                input.focus();
            }
            
        },
        clearQuery(e) {
            e.preventDefault();
            this.query = '';
        },
        closeSuggestionsHandler(e) {
            const suggestionsRef = this.$refs.suggestions;
            const inputRef = this.$refs.input;
            const target = e.target;
            
            if(suggestionsRef !== target && !(suggestionsRef as HTMLElement).contains(target as HTMLElement) && inputRef !== target && !(inputRef as HTMLElement).contains(target as HTMLElement)) {
                this.closeSuggestions(false)
            }
        },
        closeIfOutside(e) {
            const target = e.target;
            const searchRef = this.$refs.search;
            if(searchRef !== target && !(searchRef as HTMLElement).contains(target as HTMLElement) && !(target as HTMLElement).hasAttribute('data-ignore-close')) {
                this.closeSearch()
            } else {
            }

        }
	},
    computed: {
        isMobile() {
            return this.$store.getters.isMobile;
        },
        suggestions() {
            return this.$store.getters.suggestions;
        },
        searchPageURI() {
            return this.$store.getters.searchPageURI;
        },
        translations() {
            return this.$store.getters.translations;
        }
    },
    mounted() {
        (this.$refs.input as HTMLInputElement).focus();
        window.addEventListener('click', this.closeSuggestionsHandler);
        window.addEventListener('focusin', this.closeIfOutside);
        this.$data.isOpen = true;
    },
    beforeDestroy() {
        window.removeEventListener('click', this.closeSuggestionsHandler);
        window.removeEventListener('focusin', this.closeIfOutside);
    },
    destroyed() {
    }
})
