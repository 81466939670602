import { IMenuItem, IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'toggleSearch' | 'resize' | 'getMenu' | 'toggleMenu' | 'toggleExpanded' | 'getChildren' | 'setActive' | 'getSuggestions' | 'deactivateMainItem' | 'removeSuggestions'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

export interface IGetChildrenPayload {
	id: string
	level: number
}
export interface IAddMenuItemsPayload {
	menuItem: IMenuItem,
	items: IMenuItem[]
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const toggleSearch: ActionCreator<boolean> = (open) => ({
	type: 'toggleSearch',
	payload: open
})

export const toggleMenu: ActionCreator<boolean> = (open) => ({
	type: 'toggleMenu',
	payload: open
})

export const resize: ActionCreator<number> = (width) => ({
	type: 'resize',
	payload: width
})
export const getMenu: ActionCreator<undefined> = (param) => ({
	type: 'getMenu',
	payload: param
})
export const toggleExpanded: ActionCreator<IMenuItem> = menuItem => ({
	type: 'toggleExpanded',
	payload: menuItem
})
export const getChildren: ActionCreator<IGetChildrenPayload> = payload => ({
	type: 'getChildren',
	payload: payload

})
export const setActive: ActionCreator<string> = id => ({
	type: 'setActive',
	payload: id
})
export const getSuggestions: ActionCreator<string> = query => ({
	type: 'getSuggestions',
	payload: query
})
export const deactivateMainItem: ActionCreator<undefined> = () => ({
	type: 'deactivateMainItem',
	payload: undefined
})
export const removeSuggestions: ActionCreator<undefined> = () => ({
	type: 'removeSuggestions',
	payload: undefined
})
// Action handlers
const removeSuggestionsAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type: 'removeSuggestions',
		payload: payload
	})
}
const deactivateMainItemAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type: 'deactivateMainItem',
		payload: payload
	})
}
const getSuggestionsAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	const getSuggestionsURL = state.endpoints.getSuggestions;
	api.getSuggestions(getSuggestionsURL, payload)
		.then(res => {
			commit({
				type: 'setSuggestions',
				payload: res
			})
		})
}
const setActiveAction: ActionHandler<string> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'setActive',
		payload: payload
	})
}
const toggleExpandedAction: ActionHandler<IMenuItem> = ({ commit, dispatch, state }, { payload }) => {
	commit({
		type: 'toggleExpanded',
		payload: payload
	})

	if(payload.children.length < 1) {
		dispatch(getChildren(payload))
	}
}
const getChildrenAction: ActionHandler<IMenuItem> = ({ commit, state }, { payload }) => {
	api.getMenuItems(state.endpoints.getChildren, {id: payload.id, level: payload.level})
		.then(res => {
			commit({
				type: 'addMenuItems',
				payload: {menuItem: payload, items: res}
			})
		})
}
const getMenuAction: ActionHandler<undefined> = ({ commit, state }, { payload }) => {
	api.getMenu(state.endpoints.getMenu)
		.then(res => {
			commit({
				type: 'setMenu',
				payload: res
			})
		})
}
const toggleMenuAction: ActionHandler<boolean> = ({ commit }, { payload }) => {
	commit({
		type: 'toggleMenu',
		payload
	})
}
const resizeAction: ActionHandler<number> = ({ commit }, { payload }) => {
	commit({
		type: 'resize',
		payload
	})
}
const toggleSearchAction: ActionHandler<boolean> = ({ commit }, { payload }) => {
	commit({
		type: 'toggleSearch',
		payload
	})
	if(!payload) {
		commit({
			type: 'removeSuggestions',
			payload
		})
	}
}

const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	toggleSearch: toggleSearchAction,
	resize: resizeAction,
	toggleMenu: toggleMenuAction,
	getMenu: getMenuAction,
	toggleExpanded: toggleExpandedAction,
	getChildren: getChildrenAction,
	setActive: setActiveAction,
	getSuggestions: getSuggestionsAction,
	deactivateMainItem: deactivateMainItemAction,
	removeSuggestions: removeSuggestionsAction
}

export default actions;
