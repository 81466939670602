
import Vue from 'vue'
import { IMenuItem, IMenuMainItem } from '../store/state'
import { setActive, toggleExpanded } from '../store/actions'
import MenuItem from './MenuItem.vue'
interface MenuMainItemMethods {
    setActive(): void
    toggleClick(item: IMenuItem): void
}

interface MenuMainItemComputed {
	
}

interface MenuMainItemProps {
    item: IMenuMainItem,
    isActive: boolean
	
}

export default Vue.extend<{}, MenuMainItemMethods, MenuMainItemComputed, MenuMainItemProps>({
    name: 'MenuMainItem',
    props: {
		item: {},
        isActive: Boolean
	},
    methods: {
        setActive() {
            this.$store.dispatch(setActive(this.$props.item.id))
        },
        toggleClick(item) {
            this.$store.dispatch(toggleExpanded(item));
        }
    }, 
    computed: {
        levelClass() {
            return 'level-' + this.$props.item.level;
        }
    },
	components: {
		MenuItem
	}
})
