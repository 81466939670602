export interface IState {
	logotypeData: ILogotypeData[];
	links: ILink[]
	linkButtons: ILinkButton[]
	heading: string
	text: string
	listen: string
	searchOpen: boolean
	menuOpen: boolean
	isMobile: boolean
	isStartPage: boolean
	startPageURI: string
	isEnglish: boolean
	menu: IMenu,
	endpoints: {
		getMenu: string,
		getChildren: string,
		getSuggestions: string,
		searchPageURI: string
	},
	suggestions: ISuggestion[]
	translations: ITranslations
}
export interface ITranslations {
	searchUka: string
	search: string
	menu: string
	close: string
	closeMenu: string
	placeholder: string
	back: string,
	clear: string,
	logotype: string,
	toStartPage: string
}
export interface ISuggestion {
	query: string
	html: string
}
export interface IMenu {
	mainItems: IMenuMainItem[]
	isOpen: boolean
}
export interface IMenuItem {
	name: string
	uri: string
	id: string
	isCurrent: boolean
	isExpanded: boolean
	hasChildren: boolean
	level: number
	children: IMenuItem[]
}
export interface IMenuMainItem {
	name: string,
	id: string,
	isExpanded: boolean,
	menuItems: IMenuItem[]
}
export interface ILogotypeData {
	name: string,
	imageURI: string
}
export interface ILink {
	name: string,
	uri: string,
	class: string,
	id: string
}
export interface ILinkButton {
	name: string,
	uri: string,
	class: string
}


const deafultState: IState = {
	logotypeData: [],
	links: [],
	linkButtons: [],
	heading: '',
	text: '',
	listen: '',
	searchOpen: false,
	menuOpen: false,
	isMobile: false,
	isStartPage: false,
	startPageURI: '',
	isEnglish: false,
	menu: {
		mainItems: [],
		isOpen: false
	},
	endpoints: {
		getMenu: '',
		getChildren: '',
		getSuggestions: '',
		searchPageURI: ''
	},
	suggestions: [],
	translations: {
		searchUka: '',
		search: '',
		menu: '',
		close: '',
		closeMenu: '',
		placeholder: '',
		back: '',
		clear: '',
		logotype: '',
		toStartPage: ''
	}
}

export default deafultState;
