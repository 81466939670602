
import Vue from 'vue'
import { ILogotypeData, ITranslations } from '../store/state'

interface LogotypeComputed {
	logotypeData: ILogotypeData
	logotype: string
	isStartPage: boolean
	startPageURI: string
	translations: ITranslations
}

export default Vue.extend<{}, {}, LogotypeComputed, {}>({
	name: 'Logotype',
	computed: {
		logotypeData() {
			return this.$store.getters.logotypeData;
		},
		logotype() {
			return this.$store.getters.logotype;
		},
		isStartPage() {
			return this.$store.getters.isStartPage;
		},
		startPageURI() {
			return this.$store.getters.startPageURI;
		},
		translations() {
			return this.$store.getters.translations;
		}
	}
})
